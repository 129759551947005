@import '@styles/variables.scss';
@import '@styles/mixins.scss';

.container {
    max-width: 768px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
    width: 100%;
}

.top {
    @include desktop-screen() {
        margin: 5rem 0 2rem 0;
    }

    @include mobile-screen() {
        margin: 3rem 0 2rem 0;
    }

}

.bottom {

    @include desktop-screen() {
        margin: 2rem 0 5rem 0;
    }

    @include mobile-screen() {
        margin: 2rem 0 3rem 0;
    }
}

.both {

    @include desktop-screen() {
        margin: 5rem 0;
    }

    @include mobile-screen() {
        margin: 3rem 0;
    }
}

.none {
    margin: 2rem 0;
}

.soloheader {
    margin-top: 0!important;
}

