@import '@styles/variables.scss';
@import '@styles/mixins.scss';

.link {
    font-size: $size_18;

    @include mobile-screen() {
        font-size: $size_12;
    }

    font-family: fertigo-pro,
    serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: $fertigo-spacing;
}

.doublelinkcontainer {
    width: fit-content;
}

.hr {
    margin-left: 0;
    margin-right: 0;
    border: none;
    background-color: $khaki;
    border-top: thin solid $khaki;
}