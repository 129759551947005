@import '@styles/variables.scss';
@import '@styles/mixins.scss';

.listContainer {
    margin: 0 2em 0 2em;
    max-width: 500px;

    font-family: $main-font;
    font-style: normal;
    font-weight: 200;
  
    &UnderHeading {
      margin-top: 3em;
      @include mobile-screen {
        margin-top: 2em;
      }
    }
    
  
    .list {
      line-height: 2em;
      text-align: center;
  
      li {
        line-height: 1.2em;
        margin-bottom: 1em;
        font-size: $size_20;
  
        @include mobile-screen() {
          font-size: $size_16;
        }
  
      }
    }
  
    .listTitle {
      li {
        font-size: $size_16;
  
        &:first-child {
          line-height: 1.5em;
          color: $khaki;
          font-size: $size_20;
          font-weight: 500;
  
          @include mobile-screen() {
            font-size: $size_18;
          }
        }
      }
    }
  
    .leftalign {
      text-align: left;
    }
  }