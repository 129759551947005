@import '@styles/variables.scss';
@import '@styles/mixins.scss';

.grid {
    display: grid;
    gap: 10px;

    @include mobile-screen() {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    &.closer {
        margin: auto;
        width: fit-content;
        gap: 30px
    }
}

.sideMargin {
    margin: 0 1em 0 1em;
}

.height100 {
    height: 100%;
}

.reverseMobile {
    @include mobile-screen() {
        flex-direction: column-reverse;
        display: flex;
    }
}