@import '@styles/variables.scss';
@import '@styles/mixins.scss';

.p {
    font-size: $size_20;
    line-height: 1.2;

    font-family: $main-font;
    font-style: normal;
    font-weight: 200;

    @include mobile-screen() {
        font-size: $size_16;
    }

    a {
        color: $orange;

        &:hover {
            color: $orange-hover;
        }
    }

}

.default {
    margin: 1.5rem 0 2rem 0;
}

.end {
    margin: 1.5rem 0 1rem 0;
}