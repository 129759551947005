@import '@styles/mixins.scss';
@import'@styles/variables.scss';

.embla {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    //max-width: $desktop-long; Moved this into the component because of issues


    &__viewport {
        overflow: hidden;
        width: 100%;

        &.is_draggable {
            cursor: move;
            cursor: grab;
        }

        &.is-dragging {
            cursor: grabbing;
        }
    }

    &__container {
        display: flex;
        user-select: none;
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -webkit-tap-highlight-color: transparent;
        margin-left: -10px;
    }

    &__slide {
        position: relative;
        min-width: 100%;
        padding-left: 10px;

        &__inner {
            position: relative;
            overflow: hidden;
        }

        &__img {
            display: block;
            
            min-height: 100%;
            min-width: 100%;
            max-width: none;
        }
        
        .contentflex {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__button {
        outline: 0;
        cursor: pointer;
        background-color: transparent;
        touch-action: manipulation;
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        border: 0;
        width: 30px;
        height: 30px;
        justify-content: center;
        align-items: center;
        color: $white;
        padding: 0;

        &:hover {
            color: $orange;
        }

        &:disabled {
            cursor: default;
            opacity: 0.3;
        }

        @include mobile-screen() {
            &__svg {
                font-size: 24px;
            }

            &_prev {
                left: 0;
            }

            &_next {
                right: 0;
            }
        }

        @include desktop-screen() {
            &__svg {
                font-size: 30px;
            }

            &_prev {
                left: 15px;
            }

            &_next {
                right: 15px;
            }
        }
    }

    &__dots {
        display: flex;
        list-style: none;
        justify-content: center;
        padding-top: 10px;
        position: absolute;
        width: 100%;
        bottom: 1em;
    }

    &__dot {
        background-color: transparent;
        cursor: pointer;
        position: relative;
        padding: 0;
        outline: 0;
        border: 0;
        margin-right: 6px;
        margin-left: 6px;
        display: flex;
        align-items: center;

        &:after {
            background-color: #efefef;
            content: "";

            @include mobile-screen() {
                $dotSize-mob: 6px;
                width: $dotSize-mob;
                height: $dotSize-mob;
                border-radius: calc($dotSize-mob/2);
            }

            @include desktop-screen() {
                $dotSize-desk: 10px;
                width: $dotSize-desk;
                height: $dotSize-desk;
                border-radius: calc($dotSize-desk/2);
            }
        }

        &.is_selected {
            &:after {
                background-color: $orange;
                opacity: 1;
            }
        }
    }

    .img {
        object-fit: contain;
        width: 100%;
        height: auto;
    }

}



.ThreeD {
    //position: relative;
    //width: calc(100% - 5rem);
    width: 100%;
    height: 100%;
    margin: 1em;
    perspective: 1400px;
    transform-style: preserve-3d;
    //display: flex;

    //height: 901px;
    height: calc(100vw / 1.2);

    &img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }

    &__slide {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        color: white;
        font-size: 70px;
        font-weight: bold;
        border-radius: 3px;
        //cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        transition: transform 400ms ease;

        &.leftSlide {
            transform: translate3d(-30%, 0, -600px);
            opacity: 70%;
        }

        &.rightSlide {
            transform: translate3d(30%, 0, -600px);
            opacity: 70%;
        }
    }

}

.top {
    @include desktop-screen() {
        margin-top: -8rem;
    }

    @include mobile-screen() {
        margin-top: -4rem;
    }
}

.bottom {
    @include desktop-screen() {
        margin-bottom: -8rem;
    }

    @include mobile-screen() {
        margin-bottom: -4rem;
    }
}