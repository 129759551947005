@import '@styles/variables.scss';
@import '@styles/mixins.scss';

.section {
    scroll-margin-top: $nav-height;

    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include desktop-screen() {
        padding: 4rem;
    }

    @include mobile-screen() {
        padding: 2rem;
    }
}

.long {
    @include desktop-screen() {
        padding-top: 100px;
        padding-bottom: 130px;
    }

    @include mobile-screen() {
        padding-top: 50px;
        padding-bottom: 70px;
    }
}

.short {
   margin: 0;
}

.brown {
    background-color: $brown;

    h1,
    h2 {
        color: $khaki;
    }

    h3 {
        color: $white;
    }

    p {
        color: $white;
    }

    ul, ol {
        color: $white;
    }

    a {
        color: $white;

        &:hover {
            color: $orange;
        }
    }
}

.white {
    background-color: $white;

    h1,
    h2 {
        color: $khaki;
    }

    h3 {
        color: $brown;
    }

    p {
        color: $brown;
    }

    ul, ol {
        color: $brown;
    }

    a {
        color: $brown;

        &:hover {
            color: $orange;
        }
    }
}

.cream {
    background-color: $cream;

    h1,
    h2 {
        color: $khaki;
    }

    h3 {
        color: $dark;
    }

    p {
        color: $brown;
    }

    ul, ol {
        color: $brown;
    }

    a {
        color: $brown;

        &:hover {
            color: $orange;
        }
    }
}

.dark {
    background-color: $dark;

    h1,
    h2 {
        color: $khaki;
    }

    h3 {
        color: $cream;
    }

    p {
        color: $white;
    }

    ul, ol {
        color: $white;
    }

    a {
        color: $white;

        &:hover {
            color: $orange;
        }
    }
}