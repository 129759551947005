@import '@styles/variables.scss';
@import '@styles/mixins.scss';


.headingContainer {
    padding-top: 1rem;

}

.heading {
    margin: 0;
    font-family: JuliettaMessie;

    @include desktop-screen() {
        font-size: $size_56;
    }

    @include mobile-screen() {
        font-size: $size_46;
    }
}

.h3 {
    font-size: $size_20;
    font-family: $main-font;
    font-weight: 400;
    font-style: normal;
    letter-spacing: $fertigo-spacing;
    margin-top: -0.5em;
    text-transform: uppercase;

    @include mobile-screen() {
        font-size: $size_16;
    }
}