@import '@styles/mixins.scss';

.container {
    width: auto;
    height: calc(100vh - $nav-height);

    @include desktop-screen {
        min-height: 620px;
    }

    @include mobile-screen {
        min-height: 550px;
    }

    position: relative;
    margin-top: $nav-height;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .img {
        object-fit: cover; 
    }

    .title {
        position: relative;
        width: inherit;
        color: white;

        .titlecontainer {
            margin: 0 1em;

            .titleText {
                margin-top: 0;
                margin-bottom: -0.3em;
                text-align: center;
                font-family: JuliettaMessie;

                @include desktop-screen {
                    font-size: 92px;
                    //margin-left: 0.4em;
                }

                @include mobile-screen {
                    font-size: 66px;
                    //margin-left: 0.25em;
                }

                @include mobile-screen-dynamic(380px) {
                    //text-align: center;
                    font-size: 50px;
                }
            }

            .subheading {
                text-align: center;

                @include desktop-screen {
                    font-size: 46px;
                }

                @include mobile-screen {
                    font-size: 26px;
                }

                @include mobile-screen-dynamic(380px) {
                    //text-align: center;
                    font-size: 20px;
                }

                margin: 0;
            }
        }
    }

    .titleHome {
        position: relative;
        width: inherit;
        color: white;

        .titlecontainerHome {
            margin: 6em 1em 1em 1em;

            .titleTextHome {

                margin-bottom: -0.3em;
                text-align: center;
                font-family: JuliettaMessie;

                @include desktop-screen {
                    font-size: 92px;
                    //margin-left: 0.4em;
                }

                @include mobile-screen {
                    font-size: 66px;
                    //margin-left: 0.25em;
                }

                @include mobile-screen-dynamic(444px) {
                    //text-align: center;
                }
            }

            .subheadingHome {
                text-align: center;

                @include desktop-screen {
                    font-size: 46px;
                }

                @include mobile-screen {
                    font-size: 26px;
                }

                margin: 0;
            }
        }
    }

    .logocontainer {
        margin: 0 1em;
        display: flex;
        flex: 1;
        align-items: flex-end;


        .logo {
            z-index: 1;
            width:100%;
            object-fit: contain;
            object-position: bottom;
        }
        


        @include desktop-screen-dynamic(1179px) {
            margin-bottom: 250px;
        }

        @include mobile-screen-dynamic(1179px) {
            margin-bottom: 200px;
        }
    }

}

.blurContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100vh - $nav-height);
    overflow: hidden;
    z-index: -1;

    .blurDiv {
        width: auto;
        height: 100%;
        filter: blur(1.5em);
        transform: scale(2); 
    }
}

.skip {
    z-index: 1;
    position: absolute;
    transform: scale(3);
    cursor: pointer;

    @include desktop-screen() {
        bottom: 10%;
    }

    @include mobile-screen() {
        bottom: 15%;
    }

}

.subtitle {
    font-size: $size_20;
    font-family: fertigo-pro, serif;
    font-weight: 400;
    font-style: normal;
    letter-spacing: $fertigo-spacing;
}